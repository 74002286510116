<template>
  <div>
    <GreenBlock class-name="green-section--absolute" />
    <v-container class="container--margin">
      <div class="box-shadow" >
        <h1 class="section__title">Vielen Dank!</h1>
        <div class="section__img" :style="{ backgroundImage: 'url(' + require('@/assets/images/family.jpg') + ')' }"></div>
        <p>Vielen Dank! Wir freuen uns, dass Sie sich für eines unserer individuellen Strompakete entschieden haben.
          <br>Unser Team kümmert sich um Ihre Bestellung und begleitet Sie bei den nächsten Schritten.</p>
        <h2 class="section__title">Nächste Schritte</h2>
        <div class="function-track">
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon1.svg" alt="">
              <span class="count-bg"><span class="count-number">1</span></span>
            </div>
            <div>
              <p>Sie erhalten von uns eine
                Bestätigung für Ihr neues
                Strompaket und vervollständigen
                Ihre Registrierung bei mein eprimo</p>
            </div>
          </div>
          <div class="function-track__item--small">
            <div class="epicon epicon-arrow-right"></div>
          </div>
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon2.svg" alt="">
              <span class="count-bg"><span class="count-number">2</span></span>
            </div>
            <div>
              <p>Den bisherigen Verbrauch
                Ihres alten Vertrages rechnen
                wir ab und Sie erhalten dazu
                eine Endabrechnung von uns</p>
            </div>
          </div>
          <div class="function-track__item--small">
            <div class="epicon epicon-arrow-right"></div>
          </div>
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon3.svg" alt="">
              <span class="count-bg"><span class="count-number">3</span></span>
            </div>
            <div>
              <p>Ab dem nächsten Monat nutzen Sie
                Ihr eprimo Strompaket und
                profitieren von den neuen günstigen
                Konditionen</p>
            </div>
          </div>
          <div class="function-track__item--small">
            <div class="epicon epicon-arrow-right"></div>
          </div>
          <div class="function-track__item">
            <div class="function-track__circle">
              <img class="function-track__icon" src="@/assets/images/icons/icon4.svg" alt="">
              <span class="count-bg"><span class="count-number">4</span></span>
            </div>
            <div>
              <p>Regelmäßige Zählerstande
                helfen beim Überbilck über Ihr
                Paket. Wir erinnern Sie gerne
                per E-Mail an die Übermittlung</p>
            </div>
          </div>
        </div>
        <hr>
        <v-row class="section__bottom">
          <v-col sm="9">
            <v-col sm="8" class="py-0 px-0">
              <h2 class="section__title">Noch mehr Komfort und Übersicht mit dem Smart Meter </h2>
            </v-col>
            <v-col sm="11" class="py-0 px-0">
              <p>eprimo testet derzeit, ob wir mithilfe eines modernen Stromzählers die Strompakete noch attraktiver
                und einfacher gestalten können und bietet daher zu den bestehenden Strompaketen exklusiv für eine kleine
                Testgruppe und einen befristeten Zeitraum kostenfrei einen neuen, modernen und smarten Stromzähler an.</p>
            </v-col>
            <p class="font-weight-bold">Ihre Vorteile im Überblick:</p>
            <ul class="list-checkmark ">
              <li>Die regelmäßige Ablesung Ihres Zählers und der Termin zur Ablesung durch den Stromableser entfällt. </li>
              <li>Sie erhalten regelmäßig eine Übersicht über Ihren Stromverbrauch.</li>
              <li>Sie nehmen aktiv an der Erneuerung unserer Stromnetze teil.</li>
              <li>Sie erhalten in Zukunft Zugriff auf weitere smarte Services.</li>
            </ul>
            <p class="font-weight-bold">Jetzt als befristetes Vorteilsangebot für Sie komplett kostenlos:</p>
            <ul class="list-checkmark ">
              <li>kostenloser SmartMeter</li>
              <li>kostenloser Einbau des SmartMeters</li>
              <li>Entgelte für die Messung werden von eprimo übernommen.</li>
              <li>Entgelte für den digitalen Messstellenbetrieb sind bereits in Ihrem Strompaket enthalten.</li>
            </ul>
            <p>
              Bestellen Sie jetzt das zurzeit kostenlose eprimo SmartMeter-Angebot. Wir übernehmen als wettbewerblicher
              Messstellenbetreiber Ihren Messstellenbetrieb und Sie müssen keine Zählerstände mehr melden. Im nächsten
              Schritt kontaktieren wir Sie kurzfristig und vereinbaren einen Termin zum Austausch Ihres alten
              Stromzählers.
            </p>

          </v-col>
          <v-col sm="3" class="text-center">
            <img src="@/assets/images/icons/smartmeter.svg" alt="smartmeter">
          </v-col>
        </v-row>
        <div class="text-center">
          <a class="btn mb-5 mt-4" :href="`${publicPath}smartmeter/${token}`" target="_blank"><span @click="updateSmartMeter">Jetzt kostenfrei bestellen</span></a>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'ThankYou',
  components: {
    GreenBlock
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      token: ''
    }
  },
  mounted() {
    this.token = this.$cookies.get('token');
  },
  methods: {
    updateSmartMeter() {
      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/submit/' + this.$cookies.get('uuid'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Smart_Meter_Request: 1 })
      });
      this.$router.push('danke-smartmeter')
      this.$cookies.remove('uuid');
      this.$cookies.remove('token');
    }
  }
}
</script>

<style lang="scss" scoped>
.container--margin {
  margin-top: 15px;
}

.list-checkmark {
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;

  li {
    margin-bottom: 10px;
  }
}
.section__img {
  height: 35vw;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
}

.function-track {
  margin-top: 25px;

  &__block {
    margin: 30px 0 50px;
  }

  &__circle {
    width: 70px;
    height: 70px;
    border: 2px solid $green;
    background-color: $white;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .count {
    &-bg {
      position: absolute;
      bottom: -10px;
      right: -5px;
      padding: 2px;
      width: 30px;
      height: 30px;
      color: $white;
      background: $green;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 26px;
    }

    &-number {
      display: block;
      color: $white;
      border-radius: 50%;
      font-weight: 700;
      text-align: center;
      height: 100%;

      &:before {
        color: white;
        font-size: 23px;
        line-height: 40px;
      }
    }
  }

  &__item {
    font-size: 12px;
    padding: 0 10px;
  }

  .epicon {
    color: $green;
  }
}

.section__bottom {
  align-items: center;

  img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
 .function-track {
   text-align: center;

   &__item--small {
     margin: 10px;
   }

   .epicon {
     font-size: 45px;
     transform: rotate(90deg);
   }
 }

  .section__bottom {
    .col {
      flex: none;
      max-width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .container--margin {
    margin-top: 50px;
  }

  .section__img {
    height: 320px;
  }

  .function-track {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px -5px 0;

    &__item {
      width: 35%;
      padding: 0;
      margin: 0 auto;

      &:first-of-type {
        width: 40%;
      }

      &--small {
        width: 30px;

        .epicon-arrow-right {
          font-size: 64px;
          margin-top: 20px;
          margin-left: -25px;
        }
      }
    }

    &__circle {
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
    }

    .count {
      &-bg {
        width: 40px;
        height: 40px;
        font-size: 25px;
        font-weight: 700;
        padding: 4px;
        line-height: 32px;
      }
    }
  }
}

</style>
